import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { UserPreferenceIdentifier } from '@services/user/enum';
import { UserPreferenceRequestInterface } from '@services/user/interface';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	[x: string]: any;
	formInitValues = {
		subject:
			"Check out this cool Unlimited Graphic Design + more service I've been using.",
		message:
			'Hi!\n' +
			'I’m sending over a link to Flocksy. The service provides Unlimited Graphic Design, Video Editing, Motion Graphics, Copywriting, and more for one flat monthly fee. I’ve been using it and love it, and I think you would find it very useful, too.\n\n' +
			'Use this link and get $100 off your first month plus a 14-day money-back guarantee: ',
	};
	userData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	emailsList$ = new BehaviorSubject<any[]>([]);
	emailMessage$ = new BehaviorSubject<string>(this.formInitValues.message);
	emailSubject$ = new BehaviorSubject<string>(this.formInitValues.subject);
	emailVideoLink$ = new BehaviorSubject<any>(null);
	emailVideoEmbedUrl$ = new BehaviorSubject<any>(null);
	isRefLinkAttached$ = new BehaviorSubject<boolean>(false);

	constructor(
		private _customHttpService: CustomHttpService,
		private _router: Router
	) {}

	index(oParams?: object): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (typeof oParams[key] === 'object') {
				for (let key1 in oParams[key]) {
					params.set(key + '[' + key1 + ']', oParams[key][key1]);
				}
			} else {
				params.set(key, oParams[key]);
			}
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/all_user' +
				'?' +
				params.toString()
		);
	}

	updatePlanSubscription(id, data) {
		return this._customHttpService.put(
			`${API_CONSTANTS.API_ENDPOINT_URL}/admin/users/${id}/subscriptions`,
			data
		);
	}

	updateUserStatus(data: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/update_user_status',
			data
		);
	}

	createCustomMessage(id, title) {
		return this._customHttpService.post(
			`${API_CONSTANTS.API_ENDPOINT_URL}/users/creatives/${id}/custom-messages`,
			title
		);
	}

	updateCustomStatus(id, data: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/users/creatives/' +
				id +
				'/custom-messages',
			data
		);
	}

	getStripePlans(oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set('filter[' + key + ']', oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/plans' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}
	getEmailHistory(id) {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/users/' +
				id +
				'/audits?filter[field_names][]=email'
		);
	}

	getUserAudits(id, oParams): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}/audits${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	updateUserPlan(user_id: any, data: string) {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL + '/' + user_id + '/update_plan',
			data
		);
	}

	removeUserProfilePicture(user_id: any, data) {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL + '/' + user_id ,
			data
		);
	}

	updateUserType(user_id: any, data: string) {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL + '/' + user_id + '/user-types',
			data
		);
	}

	updateUserData(oData: any): Observable<any> {
		this.userData$.next({...this.userData$.value, ...oData});
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/user_information',
			oData
		);
	}

	updateUserAvailibilty(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/user_information?user_id=' + id,
			oData
		);
	}

	updateUserSettings(id, oData: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL + '/' + id + '/settings',
			oData
		);
	}

	getProfileInformation(oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/user_information' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}
	getCreativeAvailibity(oParams) {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/user_information' +
				(oParams.toString() ? '?' + oParams.toString() : '')
		);
	}

	getCreativeStats(id, page: number, key): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/users/' +
				id +
				'/stats?' +
				key +
				'=' +
				page
		);
	}

	updateCustomMessageUser(id, data) {
		return this._customHttpService.post(
			`${API_CONSTANTS.API_ENDPOINT_URL}/user_information?user_id=${id}`,
			data
		);
	}

	updateCustomMessage(id, data): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.API_ENDPOINT_URL}/user_information?user_id=${id}`,
			data
		);
	}

	changePassword(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/change_password',
			oData
		);
	}

	saveProfile(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/save_profile',
			oData
		);
	}

	getClientInfo(project_id: number, user_id: number): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project/client/info?project_id=' +
				project_id +
				'&user_id=' +
				user_id
		);
	}

	getCompletedProjects(user_id: number, page: number): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.API_ENDPOINT_URL}/users/${user_id}/projects/completed?page=${page}`
		);
	}

	storeUsersInvite(id, oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.CLIENTS_URL + '/' + id + '/sub-clients/invite',
			oData
		);
	}

	getPmUsers(): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.USERS_URL + '/pm');
	}

	getClients(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/clients'
		);
	}

	getShowcases(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/showcases'
		);
	}

	getPublicDesignerProfile(id, user_id?: any): Observable<any> {
		if (user_id)
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					id +
					'/profile?user_id=' +
					user_id
			);
		else
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL + '/' + id + '/profile'
			);
	}

	getEligibleActiveProject(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/plans/active/eligible-next-active'
		);
	}

	updateUserPref(id, data): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + id + '/user-preferences',
			data
		);
	}

	getCreatives(oParams): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				oParams[key].forEach((id) => {
					params.append(key, id);
				});
			} else {
				params.set(key, oParams[key]);
			}
		}
		let queryStr = params.toString() ? '?' + params.toString() : '';
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/creatives' + queryStr
		);
	}

	getCreativesAnalyticsActivities(id, oFilter, pageIds): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		pageIds.forEach((id) => {
			params.append('filter[page_ids][]', id);
		});
		return this._customHttpService.get(
			environment.analyticsEndPoint +
				'/users/' +
				id +
				'/page-visits' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getCreativesActivities(id, oFilter): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}

		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/creatives/' +
				id +
				'/activities' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	creativeReportConcern(creativeId, data): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL +
				'/creatives/' +
				creativeId +
				'/report-concerns',
			data
		);
	}

	getCreativeNotes(creativeId, clientId): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.CREATIVES_URL}/${creativeId}/clients/${clientId}/notes`
		);
	}

	getProjectNotesByUser(page?, id?, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set('filter[' + key + '][' + i + ']', value);
					i++;
				}
			} else {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/projects/notes?' +
				'page=' +
				page +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	getUserDetails(data) {
		const params = new URLSearchParams();
		for (const key in data) {
			params.set(key, data[key]);
		}

		return this._customHttpService.get(
			API_CONSTANTS.OAUTH_URL + '/users?' + params.toString()
		);
	}

	getArchivedProjects(user_id: number, page: number): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${user_id}/projects/archived?page=${page}`
		);
	}

	getDraftProjects(user_id: number, page: number): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${user_id}/projects/draft?page=${page}`
		);
	}

	get(userId, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}

		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${userId}?${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	addAdminCreativeUser(data: any): Observable<any> {
		return this._customHttpService.post(API_CONSTANTS.USERS_URL, data);
	}

	approveOboardingTest(id, data) {
		return this._customHttpService.post(
			`${API_CONSTANTS.ADMIN_USERS_URL}/${id}/onboarding/tests/approved`,
			data
		);
	}

	getUserFeedbacks(clientId, oParams?) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${clientId}/feedbacks?${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	getQuestionsResponse(id) {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}/survey/question-responses`
		);
	}

	getProjectTypeCount(id, oParams?) {
		let params = new URLSearchParams();
		if (Array.isArray(oParams)) {
			for (let key of oParams) {
				for (let key1 in key) {
					if (key1 === 'filter') {
						for (let filterKey in key[key1]) {
							params.set(
								`filter[${filterKey}]`,
								key[key1][filterKey]
							);
						}
					} else {
						params.set('include[' + key1 + ']', key[key1]);
					}
				}
			}
		} else {
			for (let key in oParams) {
				params.set(key, oParams[key]);
			}
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.API_ENDPOINT_URL}/users/${id}/project-types/count${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	destroy(userId): Observable<any> {
		return this._customHttpService.delete(
			`${API_CONSTANTS.ADMIN_USERS_URL}/${userId}`
		);
	}

	getUserPackageList(userId, oParams?, page?) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (key === 'sort') {
				params.set('sort', oParams[key]);
			} else {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${userId}/packages?page=${page}${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	getTeamMemberList() {
		return this._customHttpService.get(
			`${API_CONSTANTS.PACKAGES_URL}/users`
		);
	}

	redirectToUserDetailPage(userId, param?) {
		const url = this._router.serializeUrl(
			this._router.createUrlTree(['users', userId], {
				queryParams: param,
			})
		);
		window.open(url, '_blank');
	}

	getPlatinumTeamMembers(oParams?, page?) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (key === 'sort') {
				params.set('sort', oParams[key]);
			} else {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}

		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/platinum-team-members?${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	updateUsersPayment(id, data) {
		return this._customHttpService.put(
			`${API_CONSTANTS.API_ENDPOINT_URL}/users/${id}`,
			data
		);
	}

	getDesignerApprovedCategories(user_id): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${user_id}/onboarding-tests`
		);
	}

	setUserData(oData: any) {
		this.userData$.next(oData);
	}

	getUserData$() {
		return this.userData$.asObservable();
	}

	setEmailsList(emailsList: any[]) {
		this.emailsList$.next(emailsList);
	}
	setEmailMessage(emailMessage: string) {
		this.emailMessage$.next(emailMessage);
	}
	setEmailVideoLink(emailVideoLink: any) {
		this.emailVideoLink$.next(emailVideoLink);
	}
	setEmailVideoEmbedUrl(emailEmbedLink: any) {
		this.emailVideoEmbedUrl$.next(emailEmbedLink);
	}
	setEmailSubject(emailSubject: any) {
		this.emailSubject$.next(emailSubject);
	}
	setRefLinkIsAttached(isAttached: boolean) {
		this.isRefLinkAttached$.next(isAttached);
	}

	getTimezones(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/timezones'
		);
	}

	getUserPreferences(userId: any, identifier: UserPreferenceIdentifier) {
		const params = new URLSearchParams();
		params.set('identifier', identifier);
		let apiUrl = `${API_CONSTANTS.USERS_URL}/${userId}/user-preferences?${params.toString()}`;
		return this._customHttpService.get(apiUrl);
	}

	setUserPreferences(userId:any, reqBody: UserPreferenceRequestInterface) {
		let apiUrl = `${API_CONSTANTS.USERS_URL}/${userId}/user-preferences`;
		return this._customHttpService.post(apiUrl, reqBody);
	}
}
